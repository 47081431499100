import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Banking Website Wireframe | Banking Web App Wireframe | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/banking-web-app-wireframe/"
    metaDescription="Designing your own banking website? Our banking web wireframe is the perfect launch pad for your project. Sign up and try today."
    description="
    h2:A banking web wireframe to streamline your project
    <br/>
    Designing your own banking web app? There's no better place to start than with our <a:https://uizard.io/templates/website-templates/website-wireframe/>website wireframe</a>. Designed with all the main screens your project could ever need our banking wireframe for web is ready to use right out of the box.
    <br/>
    h3:Work solo or work with your team
    <br/>
    Uizard is a <a:https://uizard.io/wireframing/>wireframing tool</a> that is designed for collaboration. Whether you are working on your banking site wireframe alone or with a huge team, Uizard is here to make teamworking easier than ever before. Invite colleagues to your project or share a preview link with external stakeholders for signoff. 
    <br/>
    h3:Design with the power of AI
    <br/>
    Our banking website wireframe is ready to go right out of the box. If you do want to update or optimize the template, however, Uizard empowers you to design like never before with the power of AI. Add screens from your <a:https://uizard.io/blog/how-to-draw-wireframes-in-5-simple-steps/>hand-drawn wireframes</a> with our wireframe converter or create new screens with screenshots.
    "
    pages={[
      "Core web app landing page in mid-fidelity including clickable links and user flows",
      "Secondary screens with UX features are included to demonstrate the core structure",
      "A broad repertoire of UI components including CTAs, image blocks, titles, and headings",
      "NEW: Convert the design to a low-fidelity wireframe using Uizard's Wireframe Mode",
    ]}
    projectCode="nYpZObxjBmTb5aLJROBW"
    img1={data.image1.childImageSharp}
    img1alt="online banking web app wireframe cover"
    img2={data.image2.childImageSharp}
    img2alt="online banking web app wireframe transactions screen mid fi screen"
    img3={data.image3.childImageSharp}
    img3alt="online banking web app wireframe summary"
    img4={data.image4.childImageSharp}
    img4alt="online banking web app wireframe transactions screen low fi screen"
    img5={data.image5.childImageSharp}
    img5alt="online banking web app wireframe summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/banking-web-app-wireframe/wireframe-online-banking-app-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/banking-web-app-wireframe/wireframe-online-banking-app-web-transfer.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/banking-web-app-wireframe/wireframe-online-banking-app-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/banking-web-app-wireframe/lo-fi-online-banking-web-dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/banking-web-app-wireframe/lo-fi-online-banking-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
